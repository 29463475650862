import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePelanggan } from "./pelangganFunction";
import InfiniteScroll from "react-infinite-scroll-component";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const PelangganPage = (props) => {    
    const { listState, stateActive, handleChangeState, listData, fetchMoreData,       
        handleChangeSearch, handleKeyDownSearch, handleSearch 
    } = usePelanggan();
    const height = window.screen.height;    

    const getStateBackground = (params) => {
        if ([1, '1'].includes(params)){
            return 'bg-yellow-400 text-black'
        }else if([2, '2', 3, '3'].includes(params)){
            return 'bg-cyan-600 text-black'
        }else if ([4, '4'].includes(params)){
            return 'bg-green-600 text-white'
        }else if ([5, '5'].includes(params)){
            return 'bg-red-600 text-white'
        }else {
            return 'bg-gray-700 text-white'
        }
    }
    
    return(
        <div className='text-[13px]  bg-[#F6F6F6] overflow-hidden dark:bg-base-600'>
        {/* <div className='text-[13px] h-screen bg-red-200 pt-3'> */}
        
            <div className="">
                <div className="px-3 pt-5 relative">
                    <input
                        className='px-3 py-2 rounded focus:outline-none 
                        focus:ring-0 focus:border-blue-600 w-full
                        dark:bg-base-400 dark:text-base-100
                        focus:dark:border-base-200
                        dark:border-base-400
                        '
                        value={listData.search}
                        onChange={handleChangeSearch}
                        onKeyDown={handleKeyDownSearch}
                        name="search"
                    />
                    <button className='text-gray-400 absolute right-6 top-7' onClick={handleSearch}><FontAwesomeIcon icon={faSearch} /></button>
                </div>
                <div className="px-3 flex flex-row justify-between dark:bg-base-600 py-1 gap-2   rounded overflow-hidden overflow-x-auto  bg-[#F6F6F6]  pt-3">
                    <button className={`flex justify-center items-center  py-1 px-8 rounded shadow ${stateActive === 'all' ? 'bg-blue-500 text-white dark:bg-base-400 dark:text-base-100' : 'bg-white dark:bg-base-200 text-base-600'}`} onClick={() => handleChangeState('all')}>Semua</button>
                    { listState.data?.map((post, index) => {
                        return(
                            <button onClick={() => handleChangeState(post.id)} className={`flex justify-center items-center py-1 px-8 rounded shadow ${stateActive === post.id ? 'bg-blue-500 text-white dark:bg-base-400 dark:text-base-100' : 'bg-white dark:bg-base-200 text-base-600'}`} key={index}>{post.value}</button>
                        )
                    })}
                </div>
                <InfiniteScroll
                    dataLength={listData.data.length}
                    next={fetchMoreData}
                    hasMore={true}
                    loader={listData.isLoading}
                    height={height - 180}
                    // className="w-full h-[calc(100vh-380px)] overflow-hidden overflow-y-auto mt-3 bg-red-300"
                    // loader={<h4>Loading...</h4>}
                >                        
                    <div className='mt-[10px] mb-[90px] px-3 '>
                        { listData.data?.map((post, index) => {
                            return(
                                <div key={index} className="bg-white rounded-md px-5 py-4 mb-3 shadow-md dark:bg-base-400 dark:text-base-100">
                                    <div className="flex flex-row items-center justify-between">
                                        <div className='font-bold text-[14px]'>{post.konsumen_display?.nama}</div>
                                        <div className={`text-[10px] rounded-full px-4 py-[2px] ${getStateBackground(post.status)}`}>{post.status_display}</div>
                                    </div>
                                    <div className='text-[11px]'>{post.kode}</div>
                                    <div className='text-[11px]'>{post.produk_name}</div>
                                    <div className='text-[11px]'>Salesman {post.sales_display?.nama}</div>
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default PelangganPage;