import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHome } from './homeFunction';
import { faGear, faPersonCirclePlus, faPlug, faPlugCircleXmark } from '@fortawesome/free-solid-svg-icons';
import OmzetPage from '../Dashboard/OmzetPage/OmzetPage';
import PiutangPage from '../Dashboard/PiutangPage/PiutangPage';

const HomePage = (props) => {
    const { userInfo, infoData } = useHome(); 
    return (
        <div className='text-[13px] pt-3 bg-[#F6F6F6] dark:bg-base-600 dark:text-base-100'>
            <div className='px-3'>
                <div className='bg-[#0F98AE] w-full h-[100px] rounded-lg px-3 mt-3 dark:bg-base-400'>
                    <div className="flex flex-row gap-4 h-full items-end pt-2 text-white">
                        <div className='h-full flex  w-1/4'>
                            <img src={require('../../assets/images/bisnisman.png')} alt='bisnisman' className=''/>
                        </div>
                        <div className='flex flex-col justify-start h-full w-full pt-3'>
                            <div>Hallo {userInfo?.nama}</div>
                            <div className='text-[11px] mt-2'>{userInfo?.email}</div>
                            <div className='text-[11px] mt-0'>{userInfo?.hp}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 mt-7">
                <div className='font-bold'>Aktivitas</div>                
            </div>
            <div className="px-3 mt-3 text-gray-600">
                <div className="flex flex-row gap-4 justify-between text-[12px] font-semibold">
                    <div className='bg-white w-full flex flex-row justify-between px-5 py-2 dark:bg-base-400 dark:text-base-100'>
                        <div>
                            <div>{infoData?.daftar}</div>
                            <div>Pelanggan Daftar</div>
                        </div>
                        <div className='text-[25px] font-normal'>
                            <FontAwesomeIcon icon={faPersonCirclePlus} />
                        </div>
                    </div>                    
                    <div className='bg-white w-full flex flex-row justify-between px-5 py-2 dark:bg-base-400 dark:text-base-100'>
                        <div>
                            <div>{infoData?.tidak_isolir}</div>
                            <div>Pelanggan Aktif</div>
                        </div>
                        <div className='text-[25px] font-normal'>
                            <FontAwesomeIcon icon={faPlug} />
                        </div>
                    </div>                    
                </div>
                <div className="flex flex-row gap-4 justify-between text-[12px] font-semibold mt-2">
                    <div className='bg-white w-full flex flex-row justify-between px-5 py-2 dark:bg-base-400 dark:text-base-100'>
                        <div>
                            <div>{infoData?.terproses}</div>
                            <div>Pelanggan Diproses</div>
                        </div>
                        <div className='text-[25px] font-normal'>
                            <FontAwesomeIcon icon={faGear} />
                        </div>
                    </div>                    
                    <div className='bg-white w-full flex flex-row justify-between px-5 py-2 pr-3 dark:bg-base-400 dark:text-base-100'>
                        <div>
                            <div>{infoData?.isolir}</div>
                            <div>Pelanggan Suspend</div>
                        </div>
                        <div className='text-[25px] font-normal'>
                            <FontAwesomeIcon icon={faPlugCircleXmark} />
                        </div>
                    </div>                    
                </div>
            </div>
            {/* <div className="px-3 mt-5 py-1">
                <div className="flex flex-row justify-between px-1 bg-[#F0F0F0] py-1 rounded">

                    <div className='py-3 px-8 bg-white rounded shadow'>Day</div>
                    <div className='py-3 px-8'>Week</div>
                    <div className='py-3 px-8'>Month</div>
                </div>
            </div> */}
            <div className="px-3 mt-7">
                <div className='font-bold'>Target</div>
            </div>
            <div className='px-3 mt-4'>
                <div className="grid grid-cols-1 gap-2">
                    <div className="col-span-1 bg-white dark:bg-base-400 dark:text-base-100">                        
                        <OmzetPage/>       
                    </div>
                </div>
            </div>
            <div className='px-3 mt-4 pb-[100px]'>
                <div className="grid grid-cols-1 gap-2">
                    <div className="col-span-1 bg-white dark:bg-base-400 dark:text-base-100">                        
                        <PiutangPage/>       
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomePage