import {  useState } from "react";
import Cookies from 'universal-cookie';
import config from "../config";
import { useNavigate } from "react-router-dom";

export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : 'ba',
        color : 'danger',
        subText :  '',
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null,
        url : null
    })
    

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],        
        lastPage : 1,        
        totalRecord : 1,
        restoSelected : '',
        restoObj : []
	});
    

    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    const catchFetching = (error) => {
        let status = error && error.response && error.response.status ? error.response.status : '';
        if (status && [401, 403].includes(status)) {            
            cookies.remove(config.cookiesName, { path: '/' });	
            navigate('/login')
        }
    }    


    

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, 
    }
}