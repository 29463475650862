import { 
    faAddressBook,
    faChartSimple,
    // faCalendar, 
    faHome, faUser, 
    // faUsers
 } from "@fortawesome/free-solid-svg-icons";
import HomePage from "../views/Home/HomePage";
// import ReminderPage from "../views/ReminderPage/ReminderPage";
import PelangganPage from "../views/Pelanggan/PelangganPage";
import MenuProfilePage from "../views/Profile/MenuProfile/MenuProfilePage";
import KontakPage from "../views/Kontak/KontakPage";

export const menus = [
    {
        path : '/',
        name : 'home',
        title : 'Home',
        icon : faHome,
        element : <HomePage/>
    },
    {
        path : '/kontak',
        name : 'kontak',
        title : 'Kontak',
        icon : faAddressBook,
        element : <KontakPage/>
    },
    {
        path : '/pelanggan',
        name : 'pelanggan',
        title : 'Pelanggan',
        icon : faChartSimple,
        element : <PelangganPage/>
    },
    {
        path : '/profile',
        name : 'profile',
        title : 'Profile',
        icon : faUser,
        element : <MenuProfilePage/>
    },
];