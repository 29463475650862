import { faKey, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMenuProfile } from "./menuProfileFunction"


const MenuProfilePage = (props) => {
    const { handleLogout } = useMenuProfile();
    return(
        <div className='text-[13px]  bg-[#F6F6F6] overflow-hidden px-5 dark:bg-base-600 h-full min-h-screen'>
            <div className='bg-white rounded-xl text-gray-700 mt-8 dark:bg-base-400 dark:text-base-100'>                
                <button className='flex flex-row items-center px-5 py-4 border-b border-b-gray-200 w-full dark:border-base-200'>
                    <FontAwesomeIcon icon={faUser} />
                    <div className='ml-4'>
                        Profile
                    </div>
                </button>
                        
                <button className='flex flex-row items-center px-5 py-4 w-full border-b border-b-gray-200 dark:border-base-200'>
                    <FontAwesomeIcon icon={faKey} />
                    <div className='ml-4'>
                        Change Password
                    </div>
                </button>
                                                        
                <button className='flex flex-row items-center px-5 py-4  w-full' onClick={handleLogout}>
                    <FontAwesomeIcon icon={faPowerOff} />
                    <div className='ml-4'>
                        Logout
                    </div>
                </button>
            </div>
        </div>
    )
}
export default MenuProfilePage