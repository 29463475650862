import { useContext } from "react"
import { UserContext } from "../context/UserContext"

const Alert = ({onClose, additionalButton, additionalButtonText}) => {    
    const myState = useContext(UserContext);
    const [alertState, setAlertState] = myState.alertState;

    const handleCloseAlert = () => {
        console.log('alertState execute')
        setAlertState(alertState => ({...alertState, show: false}))
        if (onClose){
            onClose();
        }
    }


    return(
        <>
            { alertState.show &&    
                <div className='w-[100%] h-[100%] left-0 top-0 fixed bg-black/[.54]  flex flex-row px-7 justify-center items-center'>
                    <div className='bg-gray-300 rounded-lg dark:text-base-100 dark:bg-base-400'>
                        <div className='p-5 text-center'>
                            <div className='font-bold text-[13px]'>{alertState.text}</div>
                            <div className='text-[11px] mt-2'>
                                {alertState.subText}
                            </div>
                        </div>
                        <div className='flex flex-row justify-center items-center border-t border-t-gray-400 dark:border-base-200'>
                            <button className='text-blue-500 flex flex-col flex-1 justify-center items-center  py-2 text-center dark:text-base-100' onClick={handleCloseAlert}>Close</button>
                            { additionalButton &&
                                <button className='text-blue-700 border-l flex-1 border-l-gray-400 py-2 dark:border-base-200' onClick={additionalButton}>{additionalButtonText ? additionalButton : 'Save'}</button>                
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default Alert