// import { useRoutes } from 'react-router-dom'
import DefaultLayout from "../component/DefaultLayout";
// import HomePage from '../views/Home/HomePage';
// import ReminderPage from '../views/ReminderPage/ReminderPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { menus } from '../routes/menus';
import LoginPage from "../views/Auth/Login/LoginPage";
import PsbPage from "../views/Psb/PsbPage";
import ForgotPasswordPage from "../views/Auth/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "../views/Auth/ResetPassword/ResetPassword";

const Router = () => {
	return (
        <BrowserRouter>
			<Routes>
                <Route path="/" element={<DefaultLayout />}>
					{menus.map((item, index) => {
                        return (
								<Route key={index} path={item.path} element={item.element} />
							);
						}
					)}
				</Route>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/forgot-password" element={<ForgotPasswordPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route path="/create-pelanggan" element={<DefaultLayout />} >
					<Route path="/create-pelanggan" element={<PsbPage />} />
				</Route>
            </Routes>
        </BrowserRouter>
    )
}
export default Router;

// const MainRoutes = {
//     path: '/',
//     element: <DefaultLayout />,
//     children: [
//         {
//             path: '/',
//             element: <HomePage />,
//             exact : true,
//         },
//         {
//             path: '/reminder',
//             element: <ReminderPage />,
//             exact : true,
//         },
//     ]
// }


// export default function ThemeRoutes() {
//     return useRoutes([MainRoutes]);
// }