import React, {  useReducer, useState  } from 'react';
import KontakPage from '../views/Kontak/KontakPage';
import ProdukPage from '../views/Produk/ProdukPage';


const UserContext = React.createContext([{}, () => { }]);

const UserProvider = (props) => {
    const [menuState, setMenuState] = useState({        
        menu : 'home',
        show : false,
        count : 0
    });
    const [listOrder, setListOrder] = useState([]);
    const [headerState, setHeaderState]= useState({
        show: true,
        title : 'Home'
    })
    const [alertState, setAlertState] = useState({
        show: false,
        text : 'Allow "Calendar" to access your location while you use the app?',
        subText : 'Your location is used to estimate travel times to events and improve location searches',        
    })
    const [bottomState, setBottomState] = useState({
        show: true,
        tipe: '',
        content : null,
        text : '',
        title : '',
        id : 0,
        params : null,
        background : null
    })
    const [cartState, setCartState] = useState({
        show: false,
        tipe: '',
        orderSelected : null,
        text : '',
        title : '',
        id : 0,
        params : null,
        background : null,        
    })

    const modalReducer = (state, action) => {
        switch (action.type) {
            case 'OPEN':
                return {
                    isOpen: true,
                    text: action.text,
                    size: action.size,
                    content: action.content,
                    title: action.title,
                    onClose: action.onClose
                }            
            case 'KONTAK':
                return {
                    isOpen: true,
                    content: <KontakPage
                        isModal={true}
                        onClickTr={action.onClick}
                        hideButtonEdit={action.hideButtonEdit || false}
                        hideButtonDelete={action.hideButtonDelete || false}
                        hideButtonCreate={action.hideButtonCreate || false}
                    />,
                    size: action.size,
                    title: action.title,
                    onClose: action.onClose
                }            
            case 'PRODUK':
                return {
                    isOpen: true,
                    content: <ProdukPage
                        isModal={true}
                        onClickTr={action.onClick}
                        hideButtonEdit={action.hideButtonEdit || false}
                        hideButtonDelete={action.hideButtonDelete || false}
                        hideButtonCreate={action.hideButtonCreate || false}
                    />,
                    size: action.size,
                    title: action.title,
                    onClose: action.onClose
                }            
            case 'OTHERS':
                return {
                    isOpen: true,
                    title: action.title,
                    content:action.content,
                    onClose: action.onClose,
                    size: action.size,
                    id : action.id
                }
            case 'CLOSE':
                return {
                    isOpen: false,
                    text: '',
                    size: null,
                    content: null,
                    title: '',
                }
            default:
                return {
                    isOpen: false,
                    text: '',
                    size: 'lg',
                    content: null,
                    title: '',
                    onClose: null
                }
        }
    }


    const [modalState, modalDispatch] = useReducer(modalReducer, {
        isOpen: false,
        text: '',
        size: 'lg',
        content: null,
        title: '',
        onClose: null
    })

    return (
        <UserContext.Provider value={{
            menuState: [menuState, setMenuState],
            listOrder: [listOrder, setListOrder],
            bottomState : [bottomState, setBottomState],
            cartState : [cartState, setCartState],
            headerState : [headerState, setHeaderState],
            modalState: [modalState, modalDispatch],
            alertState: [alertState, setAlertState],
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider };