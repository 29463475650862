import { Fragment, useContext } from "react"
import { UserContext } from "../context/UserContext"
import { NavLink, } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { menus } from '../routes/menus'
import '../assets/css/bottomnav.css';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const BottomNav = (props) => {
    const myState = useContext(UserContext);
    const [bottomState] = myState.bottomState;

    

    return(
        <Fragment>
            { bottomState.show &&
                <div className='fixed bottom-0 left-0 bg-white w-full menu-container dark:bg-base-400 dark:text-base-100'>
                    <div className="flex flex-row justify-between px-8 py-4">
                    {/* <div className="grid grid-cols-5"> */}
                        { menus.map((menu, index) => {
                            return(
                                <NavLink  to={menu.path} key={index} className={`${[1,2].includes(index) && 'w-[40%] flex flex-row justify-center'} `}>
                                    {({isActive}) => {
                                        return(
                                            <div className={`menu ${isActive && 'active'}`}>
                                                <FontAwesomeIcon icon={menu.icon} />
                                            </div>
                                        )
                                    }
                                }   
                                </NavLink>
                            )
                        })}
                        {/* <NavLink  to={'/create-pelanggan'} className='absolute left-1/2 -top-6 w-[50px] h-[50px] bg-[#49B0C2] flex flex-row justify-center items-center rounded-full'> */}
                        <NavLink  to={'/create-pelanggan'} className='absolute left-[calc(50vw-32px)] -top-6 p-[8px] bg-[#F6F6F6]  flex flex-row justify-center items-center rounded-full dark:bg-base-400 dark:text-base-100'>
                            <div className={`text-white bg-[#49B0C2] w-[50px] h-[50px] flex flex-row justify-center items-center rounded-full`}>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                    
                        </NavLink>
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default BottomNav