import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";



export function useOmzet(props){
    const { catchFetching } = useGlobalFunction();
    const [omzet, setOmzet] = useState({
        isLoading : true,
        data : null
    })
    const fetchData = useCallback(async() => {        
        try{
            const res = await api.get(`${config.endPoint.omzet}`).then(res => res.data);
            if (res){
                setOmzet(res);
            }
            console.log('res is >>>>>', res);
        }catch(error){
            catchFetching(error);
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return {
        omzet
    }
}