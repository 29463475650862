import { useContext, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import config from "../../../config";
import { useNavigate } from "react-router-dom";


export function useForgotPassword(){
    const { isLoading, setIsLoading} = useGlobalFunction();
    const [email, setEmail] = useState('');
    const myState = useContext(UserContext);
    const [alertState, setAlertState] = myState.alertState;
    const [isError, setIsError] = useState(false);
    const [view, setView] = useState('request')
    // const [view, setView] = useState('after_submit')
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { value } = e.target;
        setEmail(value);
    }
    
    const handleBlur = (e) => {
        const { value } = e.target;
        if (value === ''){
            setIsError(true);
        }else{
            setIsError(false);
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let tmp = {
                'email' : email,
                'link' : window.location.protocol + '//' + window.location.host,
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.forgotPassword}`, newForm).then(res => res.data);
            if (res){
                setView('after_submit')
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to reset password';
            let { response } = error;          
            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertState(alertState => ({...alertState, show: true, text : msg, subText : 'Kami sedang mengalami gangguan', color : 'danger'}))
            // setAlertState(alertState => ({...alertState, show: true, text : 'Unable fetch save data', subText : 'Kami sedang mengalami gangguan'}))
        }
    }

    const handleKembali = () => {
        navigate('/login')
    }


    return {
        isLoading, handleSubmit, alertState, email, setIsError, handleChange, handleBlur, isError, view,
        handleKembali
    }
}