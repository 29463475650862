import { useContext, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import config from "../../../config";
import api from "../../../api";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

export function useLogin(){
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();
    const myState = useContext(UserContext);
    const [alertState, setAlertState] = myState.alertState
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        username : {
            'name' : 'username',
            label : 'Username',
            value : '',
            type : 'text',
            showError : false,
            errorMsg : 'isi username',
            required : true,
        },
        password : {
            name : 'password',
            label : 'Username',
            value : '',
            type : 'text',
            showError : false,
            errorMsg : 'isi username',      
            required : true,     
            showPassword : false 
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }
    
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    const handleShowPassword = (e) => {
        setFormData(formData => ({...formData,
            password : {...formData.password, showPassword : !formData.password.showPassword}
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let tmp = {}
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.value === '' && post.required){
                    post.showError = true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                return true
            })
            
            if (countError > 0){                
                setAlertState(alertState => ({...alertState, show: true, text : 'Error', subText : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                return;
            }
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.post(`${config.endPoint.login}`, newForm).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : res.results.token, 
                    'user_info' : res.results.nama,
                    'email' : res.results.email,
                    'hp' : res.results.hp
                };
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });      
                navigate('/')
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;          
            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertState(alertState => ({...alertState, show: true, text : 'Error', subText : msg, color : 'danger'}));
            return;
        }
    }
    
    const handleForgotPassword = () => {
        navigate('/forgot-password')
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue,
        formData, handleChange, handleBlur, handleShowPassword,
        handleSubmit, cookies, handleForgotPassword, alertState
    }
}