import { useCallback,  useContext,  useEffect,  useState,  } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import config from "../../config";
import api from "../../api";
import { UserContext } from "../../context/UserContext";


export function usePelanggan(props){
    const { alertValue, setAlertValue,  catchFetching, pageValue, setPageValue} = useGlobalFunction();
    const [stateActive, setStateActive] = useState('all');
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState;
    const [bottomState, setBottomState] = myState.bottomState;
    
    const [listData, setListData] = useState({
        data : [],
        obj : [],
        offset : 0,
        search : '',
        page : 1,
        lastPage : 1,
        isLoading : false,
    })
    const [listState, setListState] = useState({
        data : [],
        isLoading : false
    })

    const fetchState = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
    // const fetchState = useCallback(async(q='', limit=config.itemPerPage) => {
        setListState(listState => ({...listState, isLoading: true}))
        try{
            const res = await api.get(`${config.endPoint.billingState}`).then(res => res.data);
            if (res){
                setListState(listState => ({...listState, 
                    isLoading: false,
                    data : res.results
                }));
            }else{
                setListState(listState => ({...listState, isLoading: false}))
            }
        }catch(error){
            setListState(listState => ({...listState, isLoading: false}))
            catchFetching(error);
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {        
        setBottomState(bottomState => ({...bottomState, show : true}))
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        fetchState();
    },[fetchState])


    const handleChangeState = (params) => {
        setListData(listData => ({...listData,
            offset : 0,
            data : [],            
        }))
        setStateActive(params);
        fetchPelanggan(listData.search, config.itemPerPage, 0, params)
    }

    
    const fetchPelanggan = useCallback(async(q='', limit=config.itemPerPage, offset=0, state) => {
        setListData(listData => ({...listData, isLoading : true}))
        try{
            let params = {
                offset : offset,                    
                limit :  limit,
            }
            if (q){
                params['q'] = q;
            }            
            if (state !== 'all'){
                params['state'] = state
            }
            const res = await api.get(`${config.endPoint.billing}`, {params: params}).then(res => res.data);
            if (res){
                // setTimeout(() => {
                    setListData(listData => ({...listData,
                        data : [...listData.data, ...res.results],
                        isLoading : false,                    
                    }))
                // }, 1000)
            }else{
                setListData(listData => ({...listData, isLoading : false}))
            }
        }catch(error){
            setListData(listData => ({...listData, isLoading : false}))
            catchFetching(error);
        }
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        fetchPelanggan();
    },[fetchPelanggan])


    const fetchMoreData = () => {
        let offset = listData.offset + config.itemPerPage
        fetchPelanggan(listData.search, config.itemPerPage, offset, stateActive)
        setListData(listData => ({...listData, offset : offset}))
    }
    
    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setListData(listData => ({...listData, [name] : value}))
    }

    const handleSearch = () => {
        setListData(listData => ({...listData, data : [], offset : 0, page : 1}))
        fetchPelanggan(listData.search, config.itemPerPage, 0, stateActive);
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

     
    return {
        alertValue, setAlertValue, listData, listState, setListData, setListState,
        stateActive, handleChangeState,  pageValue, setPageValue, 
        fetchMoreData, bottomState, modalState, modalDispatch, 
        handleChangeSearch, handleSearch, handleKeyDownSearch
    }
}