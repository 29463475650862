import { Outlet } from 'react-router-dom';
import BottomNav from './BottomNav';
import Header from './Header';
import BottomSheetComp from './BottomSheetComp';
import Alert from './Alert';


const DefaultLayout = (props) => {
    return(
        <div className='relative h-screen w-full bg-[#F6F6F6] dark:bg-base-400 dark:text-base-100'>
            
            <Header/>
            <div className='mt-[42px] w-full'>
                <Outlet/>
            </div>
            <BottomNav/>
            <BottomSheetComp/>
            <Alert />

        </div>        
    )
}
export default DefaultLayout;