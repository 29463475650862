import { Fragment } from "react";
import { useResetPassword } from "./resetPasswordFunction"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Alert from "../../../component/Alert";


const ResetPasswordPage = (props) => {
    const { isLoading, handleShowPassword, handleChange, handleBlur, isExpired, formData, handleSubmit } = useResetPassword();
    return(
        <Fragment>
            { !isLoading &&
                <Fragment>
                    <Alert/>
                    { !isExpired &&                    
                        <div className='text-[13px]  bg-white overflow-hidden px-14 w-full h-screen'>        
                            <div className='mt-12 text-[24px]'>Reset Password</div>
                            <div className='mt-7'>
                                <div className='mb-2'>Password</div>
                                <div className="relative">
                                    <input 
                                        type={formData.password.showPassword ? "text" : "password"}
                                        className='w-full border-0 border-b border-b-gray-200 focus:outline-none bg-transparent'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="password"
                                        value={formData.password.value}
                                    />
                                    <button className='absolute right-0 top-0' onClick={() => handleShowPassword(formData.password.name)}>
                                        <FontAwesomeIcon icon={formData.password.showPassword ? faEyeSlash : faEye  } />
                                    </button>
                                </div>
                            </div>
                            <div className='mt-7'>
                                <div className='mb-2'>Confirm Password</div>
                                <div className="relative">
                                    <input 
                                        type={formData.confirm_password.showPassword ? "text" : "password"}
                                        className='w-full border-0 border-b border-b-gray-200 focus:outline-none bg-transparent'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="confirm_password"
                                        value={formData.confirm_password.value}
                                    />
                                    <button className='absolute right-0 top-0' onClick={() => handleShowPassword(formData.confirm_password.name)}>
                                        <FontAwesomeIcon icon={formData.confirm_password.showPassword ? faEyeSlash : faEye  } />
                                    </button>
                                </div>
                            </div>
                            <div className='mt-7'>
                                <button className='bg-blue-600 text-white w-full py-2 rounded-full' onClick={handleSubmit}>Change Password</button>
                            </div>
                        </div>            
                    }
                </Fragment>
            }

        </Fragment>
    )
}

export default ResetPasswordPage