import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Cookies from 'universal-cookie';
import config from "../../config";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import api from "../../api";

export function useHome(props){
    const { catchFetching } = useGlobalFunction();
    const myState = useContext(UserContext);
    const cookies = new Cookies();
    const { isLoading, setIsLoading } = useGlobalFunction();
    const [alertState, setAlertState] = myState.alertState
    const myCookies = cookies.get(config.cookiesName);   
    const [bottomState, setBottomState] = myState.bottomState;
    const userInfo = {
        'nama' : myCookies?.user_info,
        'email' : myCookies?.email,
        'hp' : myCookies?.hp
    }
    const [infoData, setInfoData] = useState(null);


    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                'with_user' : true
            }
            const res = await api.get(`${config.endPoint.billingResume}`, { params : params }).then(res => res.data);
            if (res){
                setInfoData(res.data)
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            catchFetching(error);
            setAlertState(alertState => ({...alertState, show : true, text : 'Unable to fetch data', subText : 'kami sedang mengalami gangguan'}))
            setTimeout(() => {
                setAlertState(alertState => ({...alertState, show : false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setAlertState, setIsLoading])

    useEffect(() => {
        fetchData();
    },[fetchData])



    useEffect(() => {        
        setBottomState(bottomState => ({...bottomState, show : true}))
        // eslint-disable-next-line
    },[]);

    return {
        bottomState, userInfo, isLoading, alertState, infoData
    }
}