import { Fragment, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { useLocation } from 'react-router-dom';
import { menus } from '../routes/menus'
// import {  useMatch, useResolvedPath } from "react-router-dom";

const Header = (props) => {
    const myState = useContext(UserContext);
    const [headerState, setHeaderState] = myState.headerState;
    const myMenus = menus;
    
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const index = myMenus.findIndex(x => x.path === currentPath)
        if (index > -1){
            setHeaderState(headerState => ({...headerState, title : myMenus[index].title}))
        }
        // eslint-disable-next-line
    }, [location]);
    
    

    return(
        <Fragment>
            { headerState.show &&
                <div className='w-full text-[13px] fixed top-0 left-0 bg-white z-40 flex flex-row px-3 justify-between items-center  h-[50px] dark:bg-base-400 dark:text-base-100'>            
                    <div></div>
                    <div className='text-center font-semibold text'>{headerState.title}</div>
                    <div className=' w-[30px] h-[30px]'>
                        <img src={require('../assets/images/5856.jpg')} className='w-[30px] h-[30px]  object-cover rounded-full' alt='usericon'/>
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default Header;