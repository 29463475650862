import { Fragment } from "react"
import { useOmzet } from "./omzetFunction";

const OmzetPage = (props) => {
    const { omzet } = useOmzet();
    return(
        <Fragment>
            <div className='bg-white p-3 w-full rounded shadow-lg dark:bg-base-400 dark:text-base-100'>
                <div className="flex flex-row justify-between w-full">
                    <div className='flex-1'>
                        <div className='text-[13px] font-bold'>Penjualan</div>                        
                    </div>
                    <div className="flex-1">
                        <div className="flex justify-end">
                            <img src={require('../../../assets/images/chart.png')} className='w-[30px]' alt="logo"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between gap-2">
                    <div>
                        <div className='mt-3 text-gray-500'>Current Period</div>
                        <div className='text-gray-500 text-[11px] italic'>{omzet?.periode}</div>
                        <div className='text-[15px] font-bold'>Rp.{omzet?.omzet_priode?.toLocaleString()}</div>
                    </div>
                    <div>
                        <div className='mt-2 text-gray-500'>Current Month</div>
                        <div className='text-[15px] font-bold'>Rp.{omzet?.omzet_current?.toLocaleString()}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default OmzetPage;