import { useCallback, useContext, useEffect, useState } from "react";
// import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "../../../config";

export function useResetPassword() {
    const myState = useContext(UserContext);
    const [alertState, setAlertState] = myState.alertState
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const [isExpired, setIsExpired] = useState(true);
    const [formData, setFormData] = useState({
        password: {
            name: 'password',
            type: 'password',
            value: '',
            required: true,
            showPassword : false,
        },
        confirm_password: {
            name: 'confirm_password',
            type: 'password',
            value: '',
            required: true,
            showPassword : false,
        },
    })
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const fetchExpiredLink = useCallback(async() => {
        try{
            let params = {
                'params' : searchParams.get('q'),
            }
            const res = await api.get(`${config.endPoint.getExpiredLink}`, { params : params}).then(res => res.data);
            if (res){
                setIsExpired(res.is_expired)
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to reset password';
            let { response } = error;          
            console.log('respos is >>>>>', response)  
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertState(alertState => ({...alertState, show: true, text : msg, subText : 'Kami sedang mengalami gangguan', color : 'danger'}))
        }
        // eslint-disable-next-line 
    },[])

    useEffect(() => {
        fetchExpiredLink();
    },[fetchExpiredLink])

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleShowPassword = (name) => {
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], showPassword: !formData[name].showPassword }
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let tmpForm = {};
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post['showError'] = true;
                    console.log('ada error >>>', index, post)
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (tmpForm['password'] !== tmpForm['confirm_password']){
                setAlertState(alertState => ({...alertState, show: true, text : 'Error', subText : 'Password dan Confirm Password tidak sama', color : 'danger'}));
                setIsLoading(false);
                return;
            }
            

            if (countError > 0){
                setIsLoading(false);
                setFormData(obj);
                setAlertState(alertState => ({...alertState, show: true, text : 'Error', subText : 'Password dan Confirm Password tidak sama', color : 'danger'}));
                return;
            }

            tmpForm['params'] = searchParams.get('q');

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.resetPassword}`, newForm).then(res => res.data);
            setIsLoading(false);
            if (res){
                navigate('/login')
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;          
            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertState(alertState => ({...alertState, show: true, text : 'Error', subText : msg, color : 'danger'}));
        }
    }

    return {
        isLoading, setIsLoading, formData, handleChange, handleBlur, alertState, handleShowPassword, isExpired,
        handleSubmit
    }
}