import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";



export function usePiutang(props){
    const { catchFetching } = useGlobalFunction();
    const [piutang, setPiutang] = useState({
        isLoading : true,
        data : null
    })
    const fetchData = useCallback(async() => {        
        try{
            const res = await api.get(`${config.endPoint.piutang}`).then(res => res.data);
            if (res){
                setPiutang(res);
            }            
        }catch(error){
            catchFetching(error);
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return {
        piutang
    }
}