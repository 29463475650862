import { useContext, useRef } from "react"
import { UserContext } from "../context/UserContext"
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'

const BottomSheetComp = (props) => {
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState;
    const sheetRef = useRef()
    
    return(
        <>
            { modalState.isOpen &&
                <div className='absolute top-0 left-0 w-full h-screen'>
                    <BottomSheet 
                        open={modalState.isOpen} 
                        onDismiss={() => modalDispatch({type: 'CLOSE'})}
                        ref={sheetRef}
                        snapPoints={({ maxHeight }) => [
                            maxHeight - maxHeight / 10,
                            maxHeight / 4,
                            maxHeight * 0.6,
                        ]}
                        className="z-[5]"
                    >
                        <div className='pt-6'>
                            { modalState.content }
                        </div>
                    </BottomSheet>
                
                </div>
            }
        </>        
    )
}
export default BottomSheetComp