import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { useKontak } from "./kontakFunction"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import FormInput from "../../component/FormInput";


const KontakPage = (props) => {
    const { listData, handleChangeSearch, handleKeyDownSearch, fetchMoreData,
        view, formData, setFormData, handleCreate, handleChange, handleSubmit,
        handleCancel, handleSearch
    } = useKontak();
    const { onClickTr } = props;
    const height = window.screen.height;    
    return(
        <div className={`pt-5 px-5 ${config.bgColor} dark:bg-base-600 dark:text-base-100 w-full h-full ${view === 'create' ? 'pb-[130px]' : 'pb-0'}`}>
            { view === 'create' &&
                <div className='bg-white px-5 py-5 rounded-xl shadow-lg mb-[50px]'>
                    <FormInput
                        {...formData.nama}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <div className="mt-5">
                        <FormInput
                            {...formData.tgl_lahir}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.hp}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.email}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.jk}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.ktp}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.alamat}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.kabupaten}
                            formData={formData}
                            setFormData={setFormData}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.kecamatan}
                            formData={formData}
                            setFormData={setFormData}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-5">
                        <FormInput
                            {...formData.desa}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <button 
                        onClick={handleSubmit}
                        className='bg-blue-600 text-white text-[13px] w-full h-full text-center py-2  rounded-full mt-5'>
                        Simpan
                    </button>
                    <button 
                        onClick={handleCancel}
                        className='bg-gray-600 text-white text-[13px] w-full h-full text-center py-2 mb-8 rounded-full mt-4'>
                        Cancel
                    </button>
                </div>
            }
            { view === 'list' &&
                <>
                    <div className="grid grid-cols-6 gap-3 ">
                        <div className='mb-5 relative col-span-5'>
                            <input
                                className='focus:outline-1 
                                focus:outline-none 
                                rounded-md
                                px-3 py-2
                                w-full border border-gray-300
                                dark:bg-base-600 dark:text-base-100
                                focus:dark:border-base-200
                                dark:border-base-400
                                '
                                name='search'
                                value={listData.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDownSearch}
                            />
                            <button className='absolute bottom-2 right-3 text-gray-500' onClick={handleSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div>
                        <div className='col-span-1 flex flex-row justify-end h-[42px]'>
                            <button className={`bg-blue-500 rounded-md text-white px-3 py-2 dark:bg-base-400`} onClick={handleCreate}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                    </div>
                    { listData.isLoading &&
                        <div className=''>Loading</div>
                    }            
                    <InfiniteScroll
                        dataLength={listData.data.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={listData.isLoading}
                        height={height - 180}
                    >
                        { !listData.isLoading && listData.data.map((post, index) => {
                                return(
                                    <div className={`shadow-md bg-white dark:bg-base-400 dark:text-base-100 rounded-lg mb-5 px-5 py-5 ${onClickTr && 'cursor-pointer'}`} key={index} onClick={() => onClickTr ? onClickTr(post) : null}>
                                        <div className="flex flex-row gap-3">
                                            <div className='w-[50px] h-[50px] bg-cyan-500 rounded-full flex justify-center items-center text-white'>{post.initial}</div>
                                            <div className='flex-1'>
                                                <div className='text-[14px] font-bold'>{post.nama}</div>
                                                <div className='text-[13px] text-gray-500 dark:text-base-200'>{post.email}</div>
                                                <div className='text-[13px] text-gray-500 dark:text-base-200'>{post.hp}</div>
                                                <div className='text-[13px] text-gray-500 dark:text-base-200 mt-2'>{post.alamat}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </InfiniteScroll>
                </>
            }
        </div>
    )
}

export default KontakPage;