import { faChevronDown, faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

const FormInput = (props) => {
    const {
        type,
        name,
        onChange,
        onBlur,
        value,
        label,
        show,
        placeholder,
        showError,
        className,
        showPassword,
        handleShowPassword,
        setFormData,
        formData,
        hideLabel,
        obj,
        inputType,
        disabled,
        errorMsg,
        textareaRows,
        onClick,
        onChangeDate,
        dateFormat,
    } = props;

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;

        if (setFormData) {
            if (formData[name].type === 'checked') {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: e.target.checked }
                }))
            } else {

                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: nilai }
                }))
            }
        }
        if (onChange) {
            onChange(e);
        }
    }

    const handleBlur = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (setFormData) {
            if (formData[name].required && value === '') {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], showError: true }
                }))
            } else {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], showError: false }
                }))
            }
        }
        if (onBlur) {
            onBlur(e);
        }
    }

    const onShowPassword = (name) => {
        if (setFormData) {
            setFormData(formData => ({
                ...formData,
                [name]: {
                    ...formData[name],
                    showPassword: !formData[name].showPassword,
                    type: !formData[name].showPassword ? 'text' : 'password'
                }
            }))
        }
        if (handleShowPassword) {
            handleShowPassword(name)
        }
    }

    const handleChangeDate = (e, name) => {
        console.log('e is >>>>', e, name)
        if (formData && setFormData) {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], value: e }
            }))
        }
        if (onChangeDate) {
            onChangeDate(e, name)
        }
    }

    return (
        <>
            {show &&
                <div>
                    <div className="relative">
                        { type === 'date' &&
                            <div className="relative">
                                <DatePicker
                                    selected={value}
                                    onChange={(e) => handleChangeDate(e, name)}
                                    dateFormat={dateFormat ? dateFormat : "dd-MM-yyyy"}
                                    // dateFormat={"HH:mm"}
                                    timeFormat={"HH:mm"}
                                    showTimeSelect={dateFormat ? true : false}
                                    // showTimeSelectOnly={showTimeSelectOnly ? showTimeSelectOnly : false }
                                    // className={`text-sm appearance-none 
                                    //     block
                                    //     w-[100%]                         
                                    //     bg-white
                                    //     text-gray-700
                                    //     border-gray-400 
                                    //     rounded py-3 
                                    //     px-2
                                    //     border 
                                    //     leading-tight 
                                    //     focus:outline-1 
                                    //     focus:outline-none 
                                    //     focus:outline-blue-400 
                                    //     focus:border-none ${className}`
                                    // }
                                    className={`first-letter:block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                                    bg-transparent rounded-lg border border-gray-300 
                                    appearance-none dark:text-white dark:border-gray-600 
                                    dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer
                                    ${className}`}
                                />
                            </div>
                        }
                        { type === 'input_button' &&
                            <div className="relative">
                                <input
                                    type={type}
                                    name={name}
                                    id={name}
                                    value={value}
                                    onChange={handleChange}
                                    className={`
                                        block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                                        bg-transparent rounded-lg border border-gray-300 
                                        appearance-none                                         
                                        dark:border-base-300 
                                        dark:bg-base-600 dark:text-base-100   
                                        dark:focus:border-base-100               
                                        focus:outline-none 
                                        focus:ring-0 focus:border-blue-600 peer
                                        ${className}
                                    `}
                                    onBlur={handleBlur}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                />
                                <button className='absolute top-3 right-2.5' onClick={onClick}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        }
                        {type === 'select' &&
                            <div className="relative">
                                <select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={name}
                                    value={value}
                                    id={name}
                                    disabled={disabled}
                                    className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                                        bg-transparent rounded-lg border border-gray-300 
                                        appearance-none 
                                        dark:border-base-300 
                                        dark:bg-base-600 dark:text-base-100   
                                        dark:focus:border-base-100               
                                        focus:outline-none 
                                        focus:ring-0 focus:border-blue-600 peer
                                        ${className}
                                    `}>
                                    {obj.map((post, index) => {
                                        return (
                                            <option value={post.value} key={index}>{post.label}</option>
                                        )
                                    })}
                                </select>
                                <div className='absolute top-3 right-2.5'>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                            </div>
                        }
                        {type === 'textarea' &&
                            <textarea
                                className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                                    bg-transparent rounded-lg border border-gray-300 
                                    appearance-none                                     
                                    dark:border-base-300 
                                    dark:bg-base-600 dark:text-base-100   
                                    dark:focus:border-base-100               
                                    focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer
                                    ${className}`
                                }
                                id={name}
                                type={type}
                                placeholder={placeholder}
                                value={value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={name}
                                rows={textareaRows}
                                disabled={disabled}
                            ></textarea>
                        }
                        {['text', 'password'].includes(type) &&
                            <input
                                type={type}
                                name={name}
                                id={name}
                                value={value}
                                onChange={handleChange}
                                className={`
                                    block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                                    bg-transparent rounded-lg border border-gray-00 
                                    appearance-none  
                                    dark:border-base-300 
                                    dark:bg-base-600 dark:text-base-100   
                                    dark:focus:border-base-100               
                                    focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer

                                    ${className}
                                `}
                                onBlur={handleBlur}
                                placeholder={placeholder}
                                disabled={disabled}
                            />
                        }
                        {!hideLabel &&
                            <label
                                htmlFor={name}
                                className={`absolute 
                                    text-sm text-gray-500 dark:text-gray-400 
                                    duration-300 transform -translate-y-4 scale-75 top-2  
                                    origin-[0] bg-white dark:bg-base-600 px-2 
                                    peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-base-100                                     
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                                    peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 
                                    peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                                    rtl:peer-focus:left-auto start-1
                                    after:dark:h-[50%] after:dark:bg-white after:dark:absolute after:dark:z-40 after:dark:top-0
                                `}
                            >
                                {label}
                            </label>
                        }
                        {inputType === 'password' &&
                            <div style={{ position: 'absolute', bottom: 12, right: 12 }} className="cursor-pointer" onClick={() => onShowPassword(name)}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </div>
                        }
                    </div>
                    {showError &&
                        <p className="text-red-500 text-xs italic mt-2">{errorMsg ? errorMsg : 'Wajib diisi'}</p>
                    }
                </div>
            }
        </>
    )
}


FormInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    show: PropTypes.bool,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    className: PropTypes.string,
    showPassword: PropTypes.bool,
    handleShowPassword: PropTypes.func,
    setFormData: PropTypes.any,
    formData: PropTypes.any,
    hideLabel: PropTypes.bool,
    obj: PropTypes.array,
    inputType: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    textareaRows: PropTypes.number,
    onClick : PropTypes.func,
    onChangeDate : PropTypes.func,
}

FormInput.defaultProps = {
    show: false,
    value: '',
    type: 'text',
    placeholder: '',
    showError: false,
    className: '',
    obj: [],
    showPassword: true,
    inputType: 'text',
    hideLabel: false,
    disabled: false,
    errorMsg: '',
    textareaRows: 3,
}
export default FormInput;