import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import config from "../../config";
import api from "../../api";
import "react-datepicker/dist/react-datepicker.css";
// import { UserContext } from "../../context/UserContext";
import Moment from 'moment'

export function useKontak() {
    const {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, catchFetching,
    } = useGlobalFunction();
    // const myState = useContext(UserContext);
    // const [alertState, setAlertState] = myState.alertState
    const [view, setView] = useState('list')
    const [listData, setListData] = useState({
        data: [],
        obj: [],
        offset: 0,
        lastPage: 0,
        isLoading: false,
        search: '',
        page: 1
    })
    const [formData, setFormData] = useState({
        nama: {
            name: 'nama',
            label: 'Nama',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'text',
            value : ''
        },
        email: {
            name: 'email',
            label: 'Email',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'text',
            value : ''
        },
        hp: {
            name: 'hp',
            label: 'HP',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'text',
            value : ''
        },
        tgl_lahir: {
            name: 'tgl_lahir',
            label: 'Tanggal Lahir',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'date',
            value : ''
        },
        jk: {
            name: 'jk',
            label: 'Jenis Kelamin',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'select',
            obj: [
                { value: '', label: 'Pilih Jenis Kelamin' },
                { value: 'laki-laki', label: 'Laki-laki' },
                { value: 'perempuan', label: 'Perempuan' },
            ],
            value : ''
        },
        ktp: {
            name: 'ktp',
            label: 'Nomor KTP',
            required: true,
            show: true,
            showError: false,
            errorMsg: '',
            type: 'text',
            value : ''
        },
        alamat : {
            name : 'alamat',
            value : '',
            label : 'Alamat',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'textarea',
        },
        kabupaten : {
            name : 'kabupaten',
            value : '',
            label : 'Kabupaten',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [],
        },
        kecamatan : {
            name : 'kecamatan',
            value : '',
            label : 'Kecamatan',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [{value : '', label : '-- Pilih Kecamatan --'}],
        },
        desa : {
            name : 'desa',
            value : '',
            label : 'Desa',
            required : true,
            show: true,
            showError : false,
            errorMsg : '',
            type: 'select',
            obj : [{value : '', label : '-- Pilih Desa / Kelurahan --'}],
        },
    })

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage) => {
        setListData(listData => ({ ...listData, isLoading: true }))
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.kontak}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(listData => ({
                    ...listData,
                    data: [...listData.data, ...res.results],
                    isLoading: false,
                    offset: offset
                }))
            } else {
                setListData(listData => ({ ...listData, isLoading: false }))
            }
        } catch (error) {
            setListData(listData => ({ ...listData, isLoading: false }))

        }
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const fetchKabupaten = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                prov : 18,
                with_prov: true
            }
            const res = await api.get(`${config.endPoint.kabupaten}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kabupaten / Kota --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                kabupaten : {...formData.kabupaten, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch kabupaten / kota ', subText : msg, color : 'danger'}))
        }
    },[setIsLoading, setAlertValue])

    const fetchKecamatan = async(kabupaten=0) => {
        setIsLoading(true);
        try{
            let params = {
                kab : kabupaten,
                with_kab: true
            }
            const res = await api.get(`${config.endPoint.kecamatan}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kecamatan --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                kecamatan : {...formData.kecamatan, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Kecamatan', subText : msg, color : 'danger'}))
        }
    }
    
    const fetchDesa = async(kecamatan=0) => {
        setIsLoading(true);
        try{
            let params = {
                kec : kecamatan,
                with_kec: true
            }
            const res = await api.get(`${config.endPoint.desa}`, {params : params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Desa / Kelurahan --'}]
            if (res) {
                res.results.map((post) => {
                    tmp.push({
                        value : post.kode,
                        label : post.nama
                    })
                    return true;
                })
            }
            setFormData(formData => ({...formData,
                desa : {...formData.desa, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }         
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Desa / Kelurahan', subText : msg, color : 'danger'}))
        }
    }

    useEffect(() => {
        fetchKabupaten();
    },[fetchKabupaten])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'kabupaten'){
            fetchKecamatan(value);
        }
        if (name === 'kecamatan'){
            fetchDesa(value);
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setListData(listData => ({ ...listData, [name]: value }))
    }

    const handleSearch = () => {
        setListData(listData => ({ ...listData, data: [] }))
        fetchData(listData.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const fetchMoreData = () => {
        let offset = listData.offset + config.itemPerPage
        fetchData(listData.search, offset, config.itemPerPage,)
        setListData(listData => ({ ...listData, offset: offset }))
    }

    const handleCreate = () => {
        setView('create');
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : ''},
            email : {...formData.email, value : ''},
            hp : {...formData.hp, value : ''},
            tgl_lahir : {...formData.tgl_lahir, value : ''},
            jk : {...formData.jk, value : ''},
            ktp : {...formData.ktp, value : ''},
            alamat : {...formData.alamat, value : ''},
            kabupaten : {...formData.kabupaten, value : ''},
            kecamatan : {...formData.kecamatan, value : ''},
            desa : {...formData.desa, value : ''},
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let tmpForm = {};
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post['showError'] = true;
                    console.log('ada error >>>', index, post)
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            tmpForm['tgl_lahir'] = Moment(tmpForm['tgl_lahir']).format('YYYY-MM-DD')

            if (countError > 0){
                setIsLoading(false);
                setFormData(obj);
                // setAlertValue(alertValue => ({...alertValue, show : true, text : 'Lengkapi Data', color : 'error'}));                
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.kontak}`, newForm).then(res => res.data);
            setIsLoading(false);
            if (res){
                setView('list');
                
                setListData(listData => ({...listData, search : tmpForm['nama'], data : []}))
                fetchData(tmpForm['nama'], listData.offset, config.itemPerPage);
            }
        }catch(error){
            setIsLoading(false);
            let { status } = error?.response;

            if (status && status === 401){
                return window.location.href = "/login"
            }else{
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data', color : 'error'}));
                // setAlertState(alertState => ({...alertState, show : true, text : 'Unable to save data', subText : 'kami sedang mengalami gangguan'}))
            }
        }
    }

    const handleCancel = () => {
        setView('list')
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, catchFetching, listData, handleChangeSearch,
        handleKeyDownSearch, fetchMoreData, handleCreate, view, formData, setFormData, handleChange, handleSubmit,
        handleCancel, handleSearch
    }
}