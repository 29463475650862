
import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import config from "../../config";
import api from "../../api";

export function useProduk(props){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, 
    } = useGlobalFunction();
    const [listData, setListData] = useState({
        data : [],
        obj : [],
        offset : 0,
        lastPage : 0,
        isLoading : false,
        search : '',
        page : 1
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setListData(listData => ({...listData, isLoading : true}))
        try{
            let params = {
                offset : offset,
                limit : limit,
                is_internet : 'yes',                
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.produk}`, { params : params}).then(res => res.data); 
                       
            if (res){
                setListData(listData => ({...listData,
                    data : [...listData.data, ...res.results],
                    isLoading : false,
                    offset : offset
                }))
            }else{
                setListData(listData => ({...listData, isLoading : false}))
            }
        }catch(error){
            setListData(listData => ({...listData, isLoading : false}))

        }
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setListData(listData => ({...listData, [name] : value}))
    }

    const handleSearch = () => {
        setListData(listData => ({...listData, data : []}))
        fetchData(listData.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const fetchMoreData = () => {
        let offset = listData.offset + config.itemPerPage
        fetchData(listData.search,  offset, config.itemPerPage,)
        setListData(listData => ({...listData, offset : offset}))
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, listData, handleChangeSearch,
        handleKeyDownSearch, fetchMoreData
    }
}