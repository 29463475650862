import Cookies from 'universal-cookie';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';

export function useMenuProfile(){
    const cookies = new Cookies();
    const navigate = useNavigate();

    const handleLogout = () => {
        cookies.remove(config.cookiesName, { path: '/' });
        navigate('/login')
    }
    return {
        handleLogout
    }
}