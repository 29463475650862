import { Fragment } from "react"
import { usePiutang } from "./piutangFunction";

const PiutangPage = (props) => {
    const { piutang } = usePiutang();
    return(
        <Fragment>
            <div className='bg-white p-3 w-full rounded shadow-lg dark:bg-base-400 dark:text-base-100'>
                <div className="flex flex-row justify-between w-full">
                    <div className='flex-1'>
                        <div className='text-[13px] font-bold'>Piutang</div>                        
                    </div>
                    <div className="flex-1">
                        <div className="flex justify-end">
                            <img src={require('../../../assets/images/unpaid.png')} className='w-[30px]' alt="logo"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between gap-2">
                    <div>
                        <div className='mt-2 text-gray-500'>Current Month</div>
                        <div className='text-[15px] font-bold'>Rp.{piutang?.piutang?.toLocaleString()}</div>
                    </div>                    
                </div>
            </div>
        </Fragment>
    )
}
export default PiutangPage;