import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProduk } from "./produkFunction";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import config from "../../config";


const ProdukPage = (props) => {
    const { listData, handleChangeSearch, handleKeyDownSearch, fetchMoreData } = useProduk();
    const { onClickTr } = props;
    const height = window.screen.height;    
    return(
        <div className={`pt-5 px-5 ${config.bgColor} pb-5`}>
            <div className="grid grid-cols-6 gap-3">
                <div className='mb-5 relative col-span-5'>
                    <input
                        className='focus:outline-1 
                        focus:outline-none 
                        rounded-xl
                        px-3 py-2
                        w-full border border-gray-300
                        '
                        name='search'
                        value={listData.search}
                        onChange={handleChangeSearch}
                        onKeyDown={handleKeyDownSearch}
                    />
                    <button className='absolute bottom-2 right-3 text-gray-500'>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                <div className='col-span-1'>
                    <button className={`bg-blue-500 rounded-xl text-white px-5 py-2`}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            { listData.isLoading &&
                <div className=''>Loading</div>
            }            
            <InfiniteScroll
                dataLength={listData.data.length}
                next={fetchMoreData}
                hasMore={true}
                loader={listData.isLoading}
                height={height - 180}
                // className="w-full h-[calc(100vh-380px)] overflow-hidden overflow-y-auto mt-3 bg-red-300"
                // loader={<h4>Loading...</h4>}
            >
                { !listData.isLoading && listData.data.map((post, index) => {
                        return(
                            <div className={`shadow-md bg-white rounded-lg mb-5 px-5 py-5 ${onClickTr && 'cursor-pointer'}`} key={index} onClick={() => onClickTr ? onClickTr(post) : null}>
                                <div className="flex flex-row gap-3">
                                    <div className='w-[50px] h-[50px] bg-white border border-gray-300 rounded-xl flex justify-center items-center text-white'>
                                        <img src={post.icon} alt="icon" className='w-full h-full'/>
                                    </div>
                                    <div className='flex-1'>
                                        <div className='text-[14px] font-bold'>{post.nama}</div>
                                        <div className='text-[13px] text-gray-500'>Rp.{post.harga_jual_display.toLocaleString()}</div>
                                        <div className='text-[13px] text-gray-500 mt-2'>Download : {post.download_speed}</div>
                                        <div className='text-[13px] text-gray-500'>Upload : {post.upload_speed}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </InfiniteScroll>
        </div>
    )
}
export default ProdukPage;